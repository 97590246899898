import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import OverviewPage from './pages/OverviewPage';
import FounderPage from './pages/FounderPage';
import DirectorsPage from './pages/DirectorsPage';
import DirectorDetail from './pages/DirectorDetail';
import SeniorsPage from './pages/SeniorsPage';
import SeniorDetail from './pages/SeniorDetail';
import InvestmentsPage from './pages/InvestmentsPage';
import Hospitality from './components/Investments/Hospitality';
import Technology from './components/Investments/Technology';
import Energy from './components/Investments/Energy';
import PressPage from './pages/PressPage';
import Education from './components/Social/Education';
import Society from './components/Social/Society';
import Environment from './components/Social/Environment';
import Art from './components/Social/Art';
import ContactPage from './pages/ContactPage';
import NewsDetail from './components/News/NewsDetail';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Ana Sayfa */}
          <Route path="/" element={<HomePage />} />
          {/* Overview Sayfası */}
          <Route path="/about/overview" element={<OverviewPage />} />
          <Route path="/about/founder" element={<FounderPage />} />
          <Route path="/about/directors" element={<DirectorsPage />} />
          <Route path="/about/directors/:id" element={<DirectorDetail />} />
          <Route path="/about/seniors" element={<SeniorsPage />} />
          <Route path="/about/seniors/:id" element={<SeniorDetail />} />
          <Route path="/investments" element={<InvestmentsPage />} />
          <Route path="/investments/hospitality" element={<Hospitality />} />
          <Route path="/investments/technology" element={<Technology />} />
          <Route path="/investments/energy" element={<Energy />} />
          <Route path="/press/news" element={<PressPage />} />
          <Route path="/press/news/:id" element={<NewsDetail />} />
          <Route path="/social/education" element={<Education/>} />
          <Route path="/social/society-economy" element={<Society/>} />
          <Route path="/social/environment" element={<Environment/>} />
          <Route path="/social/art" element={<Art/>} />
          <Route path="/contact" element={<ContactPage />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
