// src/components/News/News.jsx
import React, { useRef, useEffect } from "react";
import "./News.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import newsData from "../../data/newsData"; // Import centralized news data

// Import your custom icons
import { ReactComponent as PrevIcon } from "../../assets/images/prev.svg"; // Example SVG
import { ReactComponent as NextIcon } from "../../assets/images/next.svg"; // Example SVG

import { useNavigate } from "react-router-dom"; // For navigation

export default function News() {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);
    const navigate = useNavigate(); // Initialize navigation

    useEffect(() => {
        if (
            swiperRef.current &&
            swiperRef.current.params.navigation
        ) {
            swiperRef.current.params.navigation.prevEl = prevRef.current;
            swiperRef.current.params.navigation.nextEl = nextRef.current;

            // Initialize navigation
            swiperRef.current.navigation.destroy(); // Destroy existing navigation
            swiperRef.current.navigation.init();
            swiperRef.current.navigation.update();
        }
    }, []);

    const handleSlideClick = (id) => {
        navigate(`/press/news/${id}`);
        window.scrollTo(0, 0); // Sayfa en üstüne kaydırılır
    };

    return (
        <section className="news-section">
            <div className="container">
                {/* Header */}
                <div className="row news-title-section">
                    <div className="col-6">
                        <span className="news-highlight">News</span>
                    </div>
                    <div className="col-6 text-end">
                        <a href="/press/news" className="view-all">
                            View All
                        </a>
                    </div>
                </div>

                <div className="row news-subtitle-section">
                    <div className="col-12">
                        <p className="news-title">News & Insights</p>
                    </div>
                </div>

                {/* Description */}
                <p className="news-description">
                    Stay updated with our corporate milestones, industry insights, and
                    important announcements. Explore how we’re shaping the future
                    together.
                </p>

                {/* Slider Container for Positioning */}
                <div className="news-slider-container">
                    {/* Slider */}
                    <Swiper
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        modules={[Navigation, Pagination]}
                        navigation={{
                            prevEl: prevRef.current,
                            nextEl: nextRef.current,
                        }}
                        pagination={{ clickable: true }}
                        slidesPerView={1}
                        spaceBetween={30}
                        breakpoints={{
                            768: { slidesPerView: 2 }, // Show two slides on desktop
                        }}
                        className="news-slider"
                    >
                        {newsData.map((news) => (
                            <SwiperSlide key={news.id} className="news-slide">
                                <div
                                    className="news-card"
                                    onClick={() => handleSlideClick(news.id)}
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') handleSlideClick(news.id);
                                    }}
                                >
                                    <img
                                        src={news.image}
                                        alt={news.title}
                                        className="news-image"
                                    />
                                    <div className="news-overlay">
                                        <span className="news-date">{news.date}</span>
                                    </div>
                                    <p className="news-card-title">{news.title}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    {/* Custom Navigation Buttons */}
                    <button ref={prevRef} className="custom-nav custom-prev" aria-label="Previous Slide">
                        <PrevIcon />
                    </button>
                    <button ref={nextRef} className="custom-nav custom-next" aria-label="Next Slide">
                        <NextIcon />
                    </button>

                </div>
            </div>
        </section>
    );
}
