import React from 'react'
import HeaderSection from '../HeaderSection/HeaderSection'
import arrowIcon from '../../assets/images/next.svg'
import efes from '../../assets/images/efes.png'
import photography from '../../assets/images/photography.png'
import Navbar from '../Navbar/Navbar'
import InvestmentCard from '../Investments/InvestmentCard'
import Footer from '../Footer/Footer'
import artImage from '../../assets/images/art.png'
import bg from '../../assets/images/social-header.jpeg'

export default function Art() {

    const cardsData = [
        {
            image: efes,
            title: 'Büyük Efes Sanat',
            text: `Büyük Efes Art was established with the aim of keeping art alive in the contemporary space with the permanent exhibition of Büyük Efes Art Collection at Swissotel Büyük Efes İzmir and to support artists with the exhibitions and art activities it organizes.`,
            link: 'http://www.buyukefessanat.com'
        },
        {
            image: photography, // Residorm için görsel yolu
            title: "Istanbul Photography Museum",
            text: `Turkey’s first photography museum; İstanbul Museum of Photography has been opened in November 2011 in Kadırga on a 1000 square meters area with the support and efforts of the Fatih Municipality and the Association of the Friends of Photography. The museum houses five photo galleries, a photo archive and a library. The museum, a brand new source of culture for Istanbul, aims to support and contribute to Turkish photography to be recognized globally, to ensure visibility to talented photography artists while letting photography audience to meet new and fresh eyes, to enhance awareness trough photography and to gather photos and artists who will set the visual database of Turkish Photography.
Banu Vargı contributes to the Museum by bringing in “The Republican Era Photography Collection” to the Research Archive and by hosting The Banu Vargı Globe’s Eye Gallery.`,
            link: "http://www.istanbulfotografmuzesi.com"
        }
    ];

    return (
        <div>
            <Navbar />
            <section className='hospitality-section'>
                <HeaderSection title='Art' menuText='Social Responsibility' menuText2='Art' icon={arrowIcon} backgroundImage={bg} />
                <div className="container">
                    <div className="hospitality-central-section">
                        <div className="row">
                            <div className="col-12">
                                <p className='hospitality-subtext'>MV Holding continues to support art with the permanent art exhibition in Swissotel Buyuk Efes Izmir.
                                    The ceramic wall panel of Bedri Rahmi Eyüboğlu, a leading artist of the Turkish Contemporary Art, as well as the works of other reputable artists such as Attila Galatali, Nasip Iyem, Beril Anilanmert, Sadi Calik, Cevat Sakir Kabaagacli, Erdogan Ersen and Jale Yilmabasar were restored with special attention during the renovation project ensuring that they receive the respect they deserve and are now being exhibited throughout the hotel in line with its new architectural concept. The rest of the collection consists of modern Turkish artists’ works custom-made to conform with the Hotel’s artistic background and new concept.
                                    The Hotel now hosts an art exhibition thanks to this collection including the works of various artists, such as Devrim Erbil, Seckin Pirim, Kemal Onsoy, Alev Gozonar, Mithat Sen, Gunes Cinar and Yasam Sasmazer.
                                    A symbol of Izmir, Swissotel Buyuk Efes contributes to the enrichment of the city’s cultural heritage by displaying the world famous bronze statue of Fernando Botero, the worldwide recognized painter and sculptor from Colombia.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hospitality-cards-container">
                    {cardsData.map((card, index) => (
                        <InvestmentCard
                            key={index}
                            image={card.image}
                            title={card.title}
                            text={card.text}
                            link={card.link}
                        />
                    ))}
                </div>
            </section>
            <Footer />
        </div>
    )
}
