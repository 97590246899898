import React from "react";
import "./About.css";
import topRightImage from "../../assets/images/top-right.svg";
import bottomRightImage from "../../assets/images/bottom-left.svg";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import { Navigation, Pagination } from "swiper/modules";
// import leftArrow from "../../assets/images/prev-white.svg";
// import rightArrow from "../../assets/images/next-white.svg";
// import image1 from "../../assets/images/sanat1.jpeg";

function About() {
    return (
        <section className="about-section" id="about">
            {/* Top-Right Corner Image */}
            <img
                src={topRightImage}
                alt="Top Right Decoration"
                className="corner-image top-right"
            />

            {/* Bottom-Right Corner Image */}
            <img
                src={bottomRightImage}
                alt="Bottom Right Decoration"
                className="corner-image bottom-left"
            />

            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-12">
                        {/* Başlık */}
                        <div className="row about-title-section">
                            <div className="col-6">
                                <span className="about-highlight">About</span>
                            </div>
                            <div className="col-6 text-end">
                                <a href="/about/overview" className="view-all">View All</a>
                            </div>
                        </div>

                        <div className="row about-subtitle-section">
                            <div className="col-12">
                                <p className="about-title">Behind the <span className="gradient-text">Vision</span></p>
                            </div>
                        </div>

                        {/* Açıklama */}
                        <p className="about-description">
                            Driven by innovation, guided by values. Discover who we are, what we
                            stand for, and how we’re making an impact in the industry and beyond
                        </p>

                        <div className="row about-subtitle-section">
                            <div className="col-12">
                                <p className="about-title"><span className="gradient-text2">Mission</span> Statement</p>
                            </div>
                        </div>

                        {/* Açıklama2 */}
                        <p className="about-description">
                            We create value for all our stakeholders with our innovative initiatives and sustainable growth focus.
                        </p>
                    </div>
                    {/* <div className="col-md-6 about-slider-section">
                    <div className="swiper-container-relative">
                        <Swiper
                            navigation={{
                                nextEl: ".swiper-button-next-custom",
                                prevEl: ".swiper-button-prev-custom",
                            }}
                            pagination={{ clickable: true }}
                            modules={[Navigation, Pagination]}
                            className="about-swiper"
                        >
                            <SwiperSlide>
                                <img
                                    src={image1}
                                    alt="Slider Image 1"
                                    className="slider-image"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img
                                    src="https://via.placeholder.com/400"
                                    alt="Slider Image 2"
                                    className="slider-image"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img
                                    src="https://via.placeholder.com/400"
                                    alt="Slider Image 3"
                                    className="slider-image"
                                />
                            </SwiperSlide>
                        </Swiper>
                        <div className="swiper-button-prev-custom">
                            <img src={leftArrow} alt="Previous" />
                        </div>
                        <div className="swiper-button-next-custom">
                            <img src={rightArrow} alt="Next" />
                        </div>
                    </div>
                </div> */}
                </div>
            </div>
        </section>
    );
}

export default About;
