// src/components/Navbar/Navbar.jsx
import React from "react";
import "./Navbar.css";
import Logo from "../../assets/images/logo-big.png";
import usFlag from "../../assets/images/us_flag.svg";
import trFlag from "../../assets/images/tr_flag.svg";
import flagIcon from "../../assets/images/flag.svg";
import buildIcon from "../../assets/images/building.svg";
import briefIcon from "../../assets/images/briefcase.svg";
import usersIcon from "../../assets/images/users.svg";
import hospitalIcon from "../../assets/images/hospital.svg";
import techIcon from "../../assets/images/technology.svg";
import energyIcon from "../../assets/images/bolt.svg";
import educationIcon from "../../assets/images/education.svg";
import societyIcon from "../../assets/images/economy.svg";
import environmentIcon from "../../assets/images/environment.svg";
import artIcon from "../../assets/images/art.svg";
import arrowIcon from "../../assets/images/chevron-down.svg";

function DropdownItem({ Icon, title, description, href }) {
  return (
    <a className="dropdown-item" href={href}>
      {/* İkon */}
      <img src={Icon} alt="" className="dropdown-icon" />
      {/* Metinler */}
      <div className="dropdown-text">
        <span className="dropdown-main">{title}</span>
        <small className="dropdown-sub">{description}</small>
      </div>
    </a>
  );
}

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container">
        {/* Sol: Logo */}
        <a className="navbar-brand d-flex align-items-center" href="/">
          <img src={Logo} alt="MV Holding Logo" className="navbar-logo" />
        </a>

        {/* Mobil Buton (Toggler) */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar İçeriği */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            {/* Menü Linkleri */}
            <li className="nav-item">
              <a className="nav-link active" href="/">
                Home
              </a>
            </li>

            {/* About Us Dropdown */}
            <li className="nav-item dropdown">
              <>
                <a className="nav-link" href="/about/overview">
                  About Us
                </a>
                <button
                  id="aboutDropdownToggle"
                  className="dropdown-toggle-button"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={arrowIcon} alt="Toggle Dropdown" className="dropdown-arrow dropdown-arrow-1" />
                </button>
              </>
              <ul
                className="dropdown-menu dropdown-with-icons"
                aria-labelledby="aboutDropdownToggle"
              >
                <li>
                  <DropdownItem
                    Icon={flagIcon}
                    title="Overview"
                    description="MV Holding was founded in 1993 by Murat Vargı..."
                    href="/about/overview"
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={buildIcon}
                    title="Founder & Honorary President"
                    description="Murat Vargi is the Founder and Honorary President..."
                    href="/about/founder"
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={briefIcon}
                    title="Board Directors"
                    description="You can find more informations about Board..."
                    href="/about/directors"
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={usersIcon}
                    title="Senior Managements"
                    description="You can find more informations about Senior..."
                    href="/about/seniors"
                  />
                </li>
              </ul>
            </li>

            {/* Investments Dropdown */}
            <li className="nav-item dropdown">
                {/* Menü Adı */}
                <a className="nav-link" href="/investments">
                  Investments
                </a>
                {/* Dropdown Toggle Butonu */}
                <button
                  id="investmentsDropdownToggle"
                  className="dropdown-toggle-button"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={arrowIcon} alt="Toggle Dropdown" className="dropdown-arrow dropdown-arrow-2" />
                </button>
              {/* Dropdown Menü */}
              <ul
                className="dropdown-menu dropdown-with-icons"
                aria-labelledby="investmentsDropdownToggle"
              >
                <li>
                  <DropdownItem
                    Icon={hospitalIcon}
                    title="Hospitality"
                    description="Our hospitality projects"
                    href="/investments/hospitality"
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={techIcon}
                    title="Technology"
                    description="Innovative tech investments"
                    href="/investments/technology"
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={energyIcon}
                    title="Energy"
                    description="Sustainable energy projects"
                    href="/investments/energy"
                  />
                </li>
              </ul>
            </li>

            {/* Press Menü Öğesi */}
            <li className="nav-item">
              <a className="nav-link" href="/press/news">
                Press
              </a>
            </li>

            {/* Social Responsibility Dropdown */}
            <li className="nav-item dropdown">
              {/* Menü Adı */}
              <div className="nav-link">
                Social Responsibility
              </div>
              {/* Dropdown Toggle Butonu */}
              <button
                id="socialDropdownToggle"
                className="dropdown-toggle-button"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={arrowIcon} alt="Toggle Dropdown" className="dropdown-arrow dropdown-arrow-3" />
              </button>
              {/* Dropdown Menü */}
              <ul
                className="dropdown-menu dropdown-with-icons"
                aria-labelledby="socialDropdownToggle"
              >
                <li>
                  <DropdownItem
                    Icon={educationIcon}
                    title="Education"
                    description="MV Holding aims to contribute to sustainable dev..."
                    href="/social/education"
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={societyIcon}
                    title="Society & Economy"
                    description="MV Holding allocates a significant amount..."
                    href="/social/society-economy"
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={environmentIcon}
                    title="Environment"
                    description="MV Holding has recently been instrumental..."
                    href="/social/environment"
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={artIcon}
                    title="Art"
                    description="MV Holding continues to support art with..."
                    href="/social/art"
                  />
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="/contact">
                Contact Us
              </a>
            </li>
          </ul>

          {/* Sağ: Dil Seçici */}
          <div className="d-flex align-items-center justify-content-between language-section">
            <a
              className="nav-link"
              href="/"
            >
              <div className="d-flex align-items-center">
                <img
                  src={usFlag}
                  alt="English"
                  className="language-icon me-1"
                />
                English
              </div>
            </a>
            {/* Dropdown Toggle Butonu */}
            <button
              id="languageDropdownToggle"
              className="dropdown-toggle-button"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={arrowIcon} alt="Toggle Language Dropdown" className="dropdown-arrow dropdown-arrow-4" />
            </button>
            {/* Dropdown Menü */}
            <ul
              className="dropdown-menu dropdown-with-icons"
              aria-labelledby="languageDropdownToggle"
            >
              <li>
                <DropdownItem
                  Icon={usFlag}
                  title="English"
                  description=""
                  href="/"
                />
              </li>
              <li>
                <DropdownItem
                  Icon={trFlag}
                  title="Türkçe"
                  description=""
                  href="/tr"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
