import React from 'react';
import './HeaderSection.css';
import defaultBackground from '../../assets/images/headers-bg.jpeg'; // Varsayılan görseli içe aktar

export default function HeaderSection({ title, menuText, menuText2, icon, backgroundImage }) {
    return (
        <>
            <div
                className="overview-top-section"
                style={{
                    backgroundImage: `url(${backgroundImage || defaultBackground})`,
                }}
            >
                <h1 className="container">{title}</h1>
            </div>
            <div className="container">
                <div className="row overview-center-section">
                    <div className="col-12">
                        <p className="menu">{menuText}</p>
                        {icon && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M7.5 15L12.5 10L7.5 5" stroke="#999999" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>}
                        <p className="menu menu2">{menuText2}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
