import React, { useState, useEffect } from "react";
import "./CookieBanner.css"; // CSS dosyasını import ediyoruz

const CookieBanner = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Eğer kullanıcı daha önce karar verdiyse, banner'ı göstermeyin
        const cookieConsent = localStorage.getItem("cookieConsent");
        if (!cookieConsent) {
            setIsVisible(true);
        }
    }, []);

    const handleAllow = () => {
        localStorage.setItem("cookieConsent", "allowed");
        console.log("Cookies allowed");
        setIsVisible(false); // Banner'ı gizle
    };

    const handleDecline = () => {
        localStorage.setItem("cookieConsent", "declined");
        console.log("Cookies declined");
        setIsVisible(false); // Banner'ı gizle
    };

    if (!isVisible) {
        return null; // Eğer banner görünür değilse hiçbir şey render edilmez
    }

    return (
        <div className="cookie-banner">
            <p className="cookie-banner-text">
                We use third-party cookies in order to personalise your experience.{" "}
                <a href="/" className="cookie-banner-link">
                    Read our Cookie Policy.
                </a>
            </p>
            <div className="cookie-banner-button-container">
                <button
                    className="cookie-banner-decline-button"
                    onClick={handleDecline}
                >
                    Decline
                </button>
                <button
                    className="cookie-banner-allow-button"
                    onClick={handleAllow}
                >
                    Allow
                </button>
            </div>
        </div>
    );
};

export default CookieBanner;
