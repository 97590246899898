import React from 'react';
import './Contact.css';

export default function Contact() {
    return (
        <section className="contact-section">
            <div className="container">
                <div className='contact-section-inner'>
                    <div className="row">
                        <div className="col-12">
                            <h1 className="contact-title">Mv Holding</h1>
                            <p className="contact-subtext">
                                You can send all your suggestions, questions, and opinions to us through this contact form.
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-stretch">
                        <div className="col-md-5 order-2 order-md-1">
                            <form className="contact-form">
                                <div className="form-row">
                                    <div className="form-group col-md-6 col-12">
                                        <label htmlFor="firstName">First name</label>
                                        <input type="text" className="form-control" id="firstName" placeholder="First name" />
                                    </div>
                                    <div className="form-group col-md-6 col-12">
                                        <label htmlFor="lastName">Last name</label>
                                        <input type="text" className="form-control" id="lastName" placeholder="Last name" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" id="email" placeholder="you@company.com" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="subject">Subject</label>
                                    <input type="text" className="form-control" id="subject" placeholder="Title" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        className="form-control form-control-message"
                                        id="message"
                                        rows="5"
                                        placeholder="Message.."
                                    ></textarea>
                                </div>
                                <div className="form-group form-check">
                                    <input type="checkbox" className="form-check-input" id="privacyCheck" />
                                    <label className="form-check-label" htmlFor="privacyCheck">
                                        Your name, surname, e-mail address, phone number information that you shared with us above by pressing the send button, and your personal data that you will write in the Message section and your sensitive personal data will be sent to MV Holding A.Ş. You consent to the processing of the Web Page Contact Form within the scope of the Information About Personal Data
                                    </label>
                                </div>
                                <button type="submit" className="btn btn-danger btn-block">
                                    Send message
                                </button>
                            </form>
                        </div>
                        <div className="col-md-7 order-1 order-md-2">
                            <div className="map-container">
                                <iframe
                                    title="Google Map"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.459248605701!2d29.097413076533453!3d40.97142357135581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac64e40d685a1%3A0x984cf4e1c89d4f54!2zS1ZLIFRla25vbG9qaSDDnHLDvG5sZXJpIEdlbmVsIE3DvGTDvHJsw7zEn8O8!5e0!3m2!1str!2str!4v1733999383471!5m2!1str!2str"
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    aria-hidden="false"
                                    tabIndex="0"
                                ></iframe>
                            </div>
                            <div className="contact-adress-info">
                                <div className="contact-adress">
                                    <h5>Adress:</h5>
                                    <a href='https://maps.app.goo.gl/M9hzrKEuRSLxL3Sf7'>
                                        KVK Plaza, Bayar caddesi Gülbahar sokak, No:14 Kozyatağı,<br />34742 Istanbul / TURKEY
                                    </a>
                                </div>
                                <div className="contact-adress">
                                    <h5>Phone:</h5>
                                    <a href='tel:+902166654191'>+90 (216) 665 4191</a>
                                </div>
                                <div className="contact-adress">
                                    <h5>Email:</h5>
                                    <a href='mailto:info@mvholding.com'>info@mvholding.com</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
