import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// React yüklenince loading ekranını kaldır
const removeLoader = () => {
  const loaderWrapper = document.getElementById("loader-wrapper");
  if (loaderWrapper) {
    loaderWrapper.style.opacity = "0";
    setTimeout(() => {
      loaderWrapper.remove();
    }, 500); // Animasyon süresiyle senkronize edin
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Yükleme ekranını kaldır
removeLoader();