// src/components/Hospitality.js
import React from 'react';
import './Hospitality.css'; // If there are additional styles specific to Hospitality
import artisanImage from '../../assets/images/artisan.jpg'; // Replace with appropriate image paths
import swissOtelImage from '../../assets/images/swissotel.png';
import casafoscoloImage from '../../assets/images/casa-foscolo.png';
import residormImage from '../../assets/images/residorm.png';
import theLifeCoImage from '../../assets/images/thelifeco.png';
import beysuImage from '../../assets/images/beysu.png';
import InvestmentCard from './InvestmentCard';
import HeaderSection from '../HeaderSection/HeaderSection';
import arrowIcon from '../../assets/images/next.svg';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import bg from '../../assets/images/investments-header.jpeg';

export default function Hospitality() {
    // You can define multiple card data here or fetch from an API
    const cardsData = [
        {
            image: swissOtelImage,
            title: 'Swissotel Büyük Efes, İzmir',
            text: `Büyük Efes Oteli, dünyanın en eski şehirlerinden biri olan İzmir’in tarihi öneme sahip simgelerinden biridir. 2008 yılından bu yana Swissotel Büyük Efes olarak hizmet vermekte olan 402 odalı otel, İzmir’in dinamik iş ve alışveriş sahalarına olan yakınlığı ile hem iş hem tatil oteli olma özelliğini taşımaktadır. Otel, kongre merkezi ile yurtiçi ve uluslararası kongre, fuar ve toplantılara ev sahipliği yapmaktadır. Swissotel Büyük Efes, koruma altında olan palmiye ağaçları, yeme-içme alanları, alışveriş mekanları, spor merkezi ve sanatla bütünleşen mimarisi ile misafirlerini ağırlarken şehrin sosyal hayatının da buluşma noktası haline gelmiştir.`,
            link: 'http://www.swissotel.com/hotels/izmir/'
        },
        {
            image: artisanImage, // Residorm için görsel yolu
            title: "The Artisan M Gallery, İstanbul",
            text: `2011 yılında MV Holding bünyesine katılan ofis binası, Mercure Taksim İstanbul adı altında 2014 yılı Mayıs ayında 137 oda ile faaliyete geçmiştir. Lütfi Kırdar Kongre Merkezi, Taksim Meydanı ve Dolmabahçe Sarayı gibi önemli lokasyonlara yürüme mesafesinde olan otel Kasım 2020’de The Artisan M Gallery olarak yenilenmiştir.`,
            link: "https://www.theartisanistanbul.com/tr/"
        },
        {
            image: casafoscoloImage, // Casa Foscolo için görsel yolu
            title: "Casa Foscolo",
            text: `Beyoğlu Pera’nın ikonik yapılarından olan, General Yazgan Sokakta, Venedikli Foscolo Ailesi’nin öncülüğünde, mimar Charles Edward Göad tarafından 1890-1900 yılları arasında inşa edilen Foscolo Apartmanı, pek yakında otel olarak kapılarını misafirlerine açıyor.
            
    Sanat ve kültürel mirasın özenle saygı duyan mimari yaklaşımıyla Casa Foscolo, İstanbul’un misafirperverlik ruhunu yansıtarak konuklarına unutulmaz bir deneyim yaşatacak.`,
            link: "http://www.casafoscolo.com"
        },
        {
            image: residormImage, // Residorm için görsel yolu
            title: "Residorm Öğrenci Yurtları, Balıkesir / Kırklareli / Muğla",
            text: `Residorm, üniversite kampüslerinde öğrencilere modern yaşam alanları sunan yeni nesil yurt zinciridir. Muğla Sıtkı Koçman Üniversitesi Kötekli Kampüsü, Kırklareli Üniversitesi Kayalı Kampüsü ve Balıkesir Üniversitesi Çağış Kampüsü’nde bulunan öğrenci yurtları, 2013-2014 eğitim yılında hizmete girmiştir. Residorm, toplamda yaklaşık 3.700 yatak kapasitesi ile Türkiye’nin en büyük özel sektör öğrenci yaşam alanlarından biri olma özelliğini taşımaktadır.`,
            link: "http://www.residorm.com"
        },
        {
            image: theLifeCoImage, // The LifeCo için görsel yolu
            title: "The LifeCo",
            text: `Türkiye’yi detoks ile tanıştıran ve sağlıklı yaşam kültürünün yaygınlaşmasında öncü bir rol üstlenen The LifeCo; Bodrum, Antalya ve Phuket’teki iyi yaşam ve detoks merkezleri ile hizmet vermektedir. Sağlıklı yaşam, kilo kontrolü ve kronik hastalıklar gibi birçok destek alanında kapsamlı hizmetler sunmaktadır.`,
            link: "http://www.thelifeco.com/tr"
        },
        {
            image: beysuImage, // Beysu Konakları için görsel yolu
            title: "Beysu Konakları",
            text: `Ankara Beysukent Bölgesi’nde yer alan Beysu Konakları projesi, 150 bin metrekare alan üzerine rezidans, villa ve ticari alanlardan oluşan 364 konutluk bir gayrimenkul geliştirme projesidir. Ayrıca 8.500 metrekarelik biyolojik gölet bulunmaktadır. Proje, 2016 Aralık itibarıyla oturum başlamıştır.`,
            link: "http://www.example.com/beysu-konaklari"
        }
    ];

    return (
        <div>
            <Navbar />
            <section className='hospitality-section'>
                <HeaderSection
                    title='Hospitality'
                    menuText='Investments'
                    menuText2='Hospitality'
                    icon={arrowIcon}
                    backgroundImage={bg}
                />
                {/* <div className="container">
                    <div className="hospitality-central-section">

                        <div className="row">
                            <div className="col-12">
                                <p className='hospitality-subtext'>MV Holding’in konaklama sektöründeki otel yatırımları, Swissotel Büyük Efes İzmir ve The Artisan M Gallery İstanbul, Güzel Oteller A.Ş. çatısı altında toplanmıştır.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="hospitality-cards-container">
                    {cardsData.map((card, index) => (
                        <InvestmentCard
                            key={index}
                            image={card.image}
                            title={card.title}
                            text={card.text}
                            link={card.link}
                        />
                    ))}
                </div>
            </section>
            <Footer />
        </div>
    );
}
