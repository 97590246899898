import React from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import HeaderSection from '../HeaderSection/HeaderSection';
import './NewsDetail.css';
import image from '../../assets/images/news1.png';
import arrowIcon from '../../assets/images/next.svg';

export default function NewsDetail() {
    return (
        <>
            <Navbar />
            <section className='news-detail-section'>
                <div className='news-detail-container'>
                    <div className='news-detail-image-container'>
                        <img className='news-detail-image' src={image} alt="Casa Foscolo" />
                    </div>
                    <HeaderSection menuText="Press" menuText2="MV Holding’in iştiraki “Casa Foscolo” ..." icon={arrowIcon} />
                    <div className="container">
                        <div className='news-detail-content'>
                            <h1 className='news-detail-title'>MV Holding’in iştiraki “Casa Foscolo” Design Hotels’in İstanbul’daki en yeni markası olarak Pera’da açıldı.</h1>
                            <div className="news-detail-divider"></div>
                            <p className='news-detail-intro'>Casa Foscolo: Pera’da Tarih, Sanat ve Konforun Buluşma Noktası</p>
                            <p className='news-detail-text'>
                                İstanbul’un en popüler ve tarihi lokasyonlarından biri olan Pera’da kapılarını açan Casa Foscolo, zengin İstanbul kültürünün zamansız mirasına bir övgü niteliği taşıyor.
                                134 yıllık geçmişe sahip bina, çağdaş sanat ve edebiyatla harmanlanarak özenle restore edildi. Modern detaylarla yeniden hayat bulan bu yapı, Design Hotel ayrıcalığıyla gerçek bir İstanbul deneyimi sunuyor.
                            </p>
                            <h2 className='news-detail-subtitle'>Geçmişten Günümüze Köprü</h2>
                            <p className='news-detail-text'>
                                Beyoğlu’nun yenilenen yüzü Pera’da yer alan Casa Foscolo, 29 Ekim’de konuklarını ağırlamaya başladı. Marriott Bonvoy üyesi olan bu butik otel, geçmişin zarafetini modern konforla buluşturuyor. 1890-1900 yılları arasında, Venedikli Foscolo Ailesi tarafından denizcilik şirketleri Foscolo Mango & Co. Ltd. için apart otel olarak inşa edilen tarihi bina, titizlikle restore edildi. Klasik ve çağdaş stillerin harmanlandığı tasarımıyla, yerli ve yabancı misafirlere benzersiz bir Pera kültürü deneyimi sunuyor.
                            </p>
                            <h2 className='news-detail-subtitle'>Kültür ve Sanatın Merkezinde</h2>
                            <p className='news-detail-text'>
                                Casa Foscolo, İstiklal Caddesi, Galata Kulesi ve Tünel gibi ikonik lokasyonlara yalnızca birkaç adım uzaklıkta bulunuyor. Ayrıca İstanbul Modern, Salt Galata, Pera Müzesi, Borusan Sanat gibi sanat ve kültür merkezlerine yakınlığıyla dikkat çekiyor.
                            </p>
                            <p className='news-detail-text'>
                                Binanın restorasyon süreci, mimar Emre Kuzlu tarafından yönetildi. Tarihi dokuyu korumak amacıyla, Mimar Sinan Güzel Sanatlar Üniversitesi öğretim görevlileriyle iş birliği yapılarak tavan süslemeleri aslına uygun biçimde restore edildi. Ressam Hakan Özdil tarafından yapılan duvar resimleri ise otelin sanatsal atmosferine katkıda bulundu. Binanın merkezinde yer alan tarihi spiral merdiven, ziyaretçileri büyüleyen detaylardan biri olarak öne çıkıyor.
                            </p>
                            <h2 className='news-detail-subtitle'>Sanatla Çevrili Bir Deneyim</h2>
                            <p className='news-detail-text'>
                                Casa Foscolo, konuklarına yalnızca konaklama değil, sanatla iç içe bir yaşam deneyimi sunmayı hedefliyor. Otelin çağdaş sanat koleksiyonunda, Ayşegül Süter, Nancy Atakan, Rafael Silveira, Marco di Giovanni gibi sanatçıların eserleri yer alıyor. Yerel ve uluslararası sanatçıların eserlerini buluşturan geniş koleksiyon, otel genelindeki sergiler ve atölyelerle destekleniyor. Ayrıca odalara özel olarak oluşturulan sanat eserleri, yaşam alanlarına yaratıcı dokunuşlar katıyor.
                            </p>
                            <h2 className='news-detail-subtitle'>Konfor ve Butik Hizmetin Buluşması</h2>
                            <p className='news-detail-text'>
                                1+1 ve 2+1 suit konseptine sahip odalarıyla, misafirlerine ev konforu sunan Casa Foscolo, butik otel yapısı sayesinde her konuğuyla birebir ilgileniyor. Otel, konuklarına unutulmaz anılar biriktirme fırsatı sunmayı hedefliyor. Otelin bünyesindeki Minoa Bar ve özel sergi alanları, hem keyifli buluşmalara hem de yaratıcı etkinliklere ev sahipliği yapacak şekilde tasarlandı.
                            </p>
                            <h2 className='news-detail-subtitle'>Sanat ve Kültürle Özel Bir Konaklama</h2>
                            <p className='news-detail-text'>
                                Casa Foscolo, tarihî modern detaylarla buluştuğu, sıcak bir aile ortamında unutulmaz bir konaklama deneyimi sunuyor. Beyoğlu’nun kalbinde yer alan bu özel otel, sanat ve kültürü bir araya getirerek, misafirlerini arasında etkileşimli ve kültürel alışverişi teşvik ediyor.
                            </p>
                            <p className='news-detail-text'>
                                Pera’nın yenilenen yüzünde, tarihî atmosferi ve modern detaylarıyla Casa Foscolo, sizi İstanbul’un büyüleyici dünyasına davet ediyor.
                            </p>
                            <div className="news-detail-divider"></div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
