// src/components/Energy.js
import React from 'react';
import dostImage from '../../assets/images/dost-enerji.png';
import InvestmentCard from '../Investments/InvestmentCard';
import HeaderSection from '../HeaderSection/HeaderSection';
import arrowIcon from '../../assets/images/next.svg';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import bg from '../../assets/images/investments-header.jpeg';

export default function Energy() {
    // You can define multiple card data here or fetch from an API
    const cardsData = [
        {
            image: dostImage,
            title: 'Dost Enerji',
            text: `MV Holding’in çoğunluk hissesine sahip olduğu Dost Enerji, sürdürülebilir büyüme modeline inanmakta ve bu doğrultuda ülkemizin ve içinde bulunduğumuz bölgenin mevcut kaynaklarının verimli bir biçimde yönetilmesini ve bunlardan maksimum şekilde faydalanılmasını amaçlamaktadır.
Bu bağlamda şirketin faaliyet konusu rüzgar enerjisi üretimi ve ticaretidir. Dost Enerji önümüzdeki üç yıllık süre içinde 240 MW kurulu güce ulaşmayı hedeflemektedir.
2007 yılında faaliyete geçen Dost Enerji’nin şu anda İzmir Aliağa’da 60 MW kurulu güçle Yuntdağ, İzmir Urla’da 25 MW kurulu güçle Kocadağ-2, Manisa Kırkağaç’ta 30 MW kurulu güçle Geres ve yine İzmir Aliağa’da 70 MW kurulu güçle Bergres faaliyette olan santralleridir.`,
            link: 'http://www.dostenerji.com'
        },
        
    ];

    return (
        <div>
            <Navbar />
            <section className='hospitality-section'>
                <HeaderSection
                    title='Energy'
                    menuText='Investments'
                    menuText2='Energy'
                    backgroundImage={bg}
                    icon={arrowIcon}
                />
                <div className="hospitality-cards-container">
                    {cardsData.map((card, index) => (
                        <InvestmentCard
                            key={index}
                            image={card.image}
                            title={card.title}
                            text={card.text}
                            link={card.link}
                        />
                    ))}
                </div>
            </section>
            <Footer />
        </div>
    );
}
