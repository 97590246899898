import React from 'react'
import HeaderSection from '../HeaderSection/HeaderSection'
import arrowIcon from '../../assets/images/next.svg'
import Card from '../Card/Card'
import hospitality from '../../assets/images/hospitality-card.png'
import technology from '../../assets/images/tech-card.png'
import energy from '../../assets/images/energy-card.png'
import './Investments.css'

export default function Investments() {

    const handleCardClick = (title) => {
        window.location.href = `/investments/${title.toLowerCase()}`;
    };

    return (
        <section className='investments-section'>
            <HeaderSection
                title='Investments'
                menuText='Investments'
            />
            <div className="investments-central-section">
                <div className="container">
                    <div className="investments-card">
                        <div className="investments-card-1">
                            <Card imageSrc={hospitality} title="HOSPITALITY" onClick={() => handleCardClick('HOSPITALITY')} />
                        </div>
                        <div className="investments-card-2">
                            <Card imageSrc={technology} title="TECHNOLOGY" onClick={() => handleCardClick('TECHNOLOGY')}/>
                        </div>
                        <div className="investments-card-3">
                            <Card imageSrc={energy} title="ENERGY" onClick={() => handleCardClick('ENERGY')}/>
                        </div>
                    </div>                        
                </div>
            </div>
        </section>
    )
}
