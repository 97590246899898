import React from "react";
import "./Footer.css";
import Logo from "../../assets/images/logo-big.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row footer-section">
          {/* About Us Section */}
          <div className="col-md-2 col-sm-6 footer-column">
            <h3 className="footer-title">About Us</h3>
            <ul className="footer-links">
              <li><a href="/about/overview">Overview</a></li>
              <li><a href="/about/founder">Founder & Honorary President</a></li>
              <li><a href="/about/directors">Board Directors</a></li>
              <li><a href="/about/seniors">Senior Managements</a></li>
            </ul>
          </div>

          {/* Investments Section */}
          <div className="col-md-2 col-sm-6 footer-column">
            <h3 className="footer-title">Investments</h3>
            <ul className="footer-links">
              <li><a href="/investments/hospitality">Hospitality</a></li>
              <li><a href="/investments/technology">Technology</a></li>
              <li><a href="/investments/energy">Energy</a></li>
            </ul>
          </div>

          {/* Press Section */}
          <div className="col-md-2 col-sm-6 footer-column">
            <h3 className="footer-title">Press</h3>
            <ul className="footer-links">
              <li><a href="/press/news">News</a></li>
              {/* <li><a href="/press/gallery">Gallery</a></li> */}
            </ul>
          </div>

          {/* Social Responsibility Section */}
          <div className="col-md-2 col-sm-6 footer-column">
            <h3 className="footer-title">Social Responsibility</h3>
            <ul className="footer-links">
              <li><a href="/social/education">Education</a></li>
              <li><a href="/social/society-economy">Society & Economy</a></li>
              <li><a href="/social/environment">Environment</a></li>
              <li><a href="/social/art">Art</a></li>
            </ul>
          </div>

          {/* Legal Section */}
          <div className="col-md-2 col-sm-6 footer-column">
            <h3 className="footer-title">Legal</h3>
            <ul className="footer-links">
              <li><a href="#">Privacy</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="footer-bottom row align-items-center">
          <div className="col-md-6 col-sm-12 footer-logo">
            <img src={Logo} alt="MV Holding Logo" />
          </div>
          <div className="col-md-6 col-sm-12 text-md-end text-sm-center footer-copyright">
            <p>Copyright © 2024,  MV Holding A.Ş.  –  All rights reserved. </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
