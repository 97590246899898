import React from 'react'
import HeaderSection from '../HeaderSection/HeaderSection'
import arrowIcon from '../../assets/images/next.svg'
import ted from '../../assets/images/ted.png'
import erg from '../../assets/images/erg.png'
import koc from '../../assets/images/koc.png'
import book from '../../assets/images/book.png'
import gbiad from '../../assets/images/gbiad.png'
import Navbar from '../Navbar/Navbar'
import InvestmentCard from '../Investments/InvestmentCard'
import Footer from '../Footer/Footer'
import bg from '../../assets/images/social-header.jpeg'


export default function Education() {

    const cardsData = [
        {
            image: ted,
            title: 'TED Istanbul College',
            text: `Turkish Education Fund TED was founded 81 years ago in accordance with the directions and the principles established by Ataturk, the chief teacher of Turkey, and its purpose is to raise young generations who celebrate the secular republic and live in accordance with its principles.`,
            link: 'www.tedistanbul.k12.tr'
        },
        {
            image: erg, // Residorm için görsel yolu
            title: "ERG – The Educational Reform Initiative",
            text: `The Educational Reform Initiative primarily brings bureaucrats, universities, schools and non-governmental organizations to support the educational reform and provides a pluralistic and creative idea creation process.
The communication and cooperation networks created activate joint minds and skills in connection with different reform areas and support the research, defense and monitoring activities.`,
            link: "http://www.egitimreformugirisimi.org"
        },
        {
            image: koc, // Casa Foscolo için görsel yolu
            title: "Koç University Anatolian Scholarships Program",
            text: `The aim of the Anatolian Scholarships program is to contribute education by uniting the successful students from developing regions of Anatolia with Koc University’s distinct facilities, thus raising well educated individuals to the society.
MV Holding continues to support education by providing scholarships to underprivileged, successful students from Anatolia.`,
            link: "https://anadolubursiyerleri.ku.edu.tr"
        },
        {
            image: book, // Residorm için görsel yolu
            title: "Book for Everyone Foundation",
            text: `The aim of the foundation is to build, develop and spread the reading habbit in Turkey and to provide that each book is efficiently delivered to every part of Turkey and all readers have access to books in easy, fast, trustable and economic conditions. The Foundations aims to maintain the infrastucture that the books are passed to next generations.`,
            link: "http://www.herkesekitapvakfi.org"
        },
        {
            image: gbiad, // The LifeCo için görsel yolu
            title: "GBIAD – First Step to Power Alliance",
            text: `First Step to Power Alliance Association supports the education of veterans through scholarships. The association also provides support to disadvantaged families of veterans`,
            link: "http://gbiad.org.tr"
        }
    ];

    return (
        <div>
            <Navbar />
            <section className='hospitality-section'>
                <HeaderSection title='Education' menuText='Social Responsibility' menuText2='Education' icon={arrowIcon} backgroundImage={bg} />

                <div className="container">
                    <div className="hospitality-central-section">

                        <div className="row">
                            <div className="col-12">
                                <p className='hospitality-subtext'>MV Holding aims to contribute to sustainable development primarily in the fields of Education, Environment, Society and Art by supporting Turkey’s leading foundations, nongovernmental organizations and initiatives. Murat Vargi pioneered the establishment of TED Istanbul College Foundation in 1997 and he has been supporting the College since then. Murat Vargi is a member of the Board of Trustees of TED Istanbul College. The Foundation provides scholarships to students under the name “TED Torch of Education”. In 2008, “Birgul Vargi” building which includes laboratories, university consulting offices and a library, was opened in TED Istanbul College campus. The Anatolian High School and the Primary School built in Diyarbakir Cermik by KVK in the memory of Ercan Demirkol, an employee who lost his life in a plane crash. The school was opened in 2008 and a student dormitory with a bed capacity of 136 was put into service in the same year. MV Holding also supports the Education Reform Initiative at the Sabanci Universite.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hospitality-cards-container">
                    {cardsData.map((card, index) => (
                        <InvestmentCard
                            key={index}
                            image={card.image}
                            title={card.title}
                            text={card.text}
                            link={card.link}
                        />
                    ))}
                </div>
            </section>
            <Footer />
        </div>

    )
}
