// src/data/newsData.js

import newsImage1 from "../assets/images/news1.png";
import newsImage2 from "../assets/images/news2.png";
import newsImage3 from "../assets/images/news3.png";
import newsImage4 from "../assets/images/news4.png";
import newsImage5 from "../assets/images/artisan.jpg";

const newsData = [
    {
        id: 1,
        date: "19 Jan 2024",
        title: 'MV Holding affiliate "Casa Foscolo," opened its doors as the newest brand of Design Hotels in Pera, Istanbul.',
        image: newsImage1,
        content: `Casa Foscolo, one of the newest boutique hotels in Istanbul, has opened its doors in Pera, one of the city’s most popular and historic locations. Carefully restored as a tribute to the timeless heritage of Istanbul’s rich culture, this 134-year-old building has been elegantly enhanced with modern details. Merging contemporary art, literature, and history, Casa Foscolo invites guests to experience the essence of Istanbul with the distinction of a Design Hotel.`,
    },
    {
        id: 2,
        date: "19 Jan 2024",
        title: 'MV Holding Transfers Investment in Yazara to Global Payments',
        image: newsImage2,
        content: `MV Holding announced the successful exit from its investment in Yazara, transferring ownership to Global Payments. This strategic move highlights MV Holding’s commitment to continuously evolving its investment portfolio and focusing on new growth opportunities.`,
    },
    {
        id: 3,
        date: "19 Jan 2024",
        title: 'Sale of MV Holding’s 111 Strand Project to IINO Kaiun Kaisha',
        image: newsImage3,
        content: `MV Holding announces the sale of 111 Strand project, a joint investment with Esas Holding, to IINO Kaiun Kaisha, one of Japan’s largest maritime groups.`,
    },
    {
        id: 4,
        date: "19 Jan 2024",
        title: 'Dost Enerji Commences Galatya Wind Power Plant Project',
        image: newsImage4,
        content: `Dost Enerji, MV Holding’s energy subsidiary, has commenced the Galatya Wind Power Plant project in Kütahya as its fifth wind energy plant. The new plant will increase Dost Enerji’s installed capacity from 185 MW to 235 MW, making a remarkable 50 MW contribution to Turkey’s renewable energy capacity.`,
    },
    {
        id: 5,
        date: "19 Jan 2024",
        title: 'The Artisan M Gallery İstanbul Hotel opens in Gumussuyu',
        image: newsImage5,
        content: `The Artisan M Gallery Istanbul Hotel, which will stand out with its value for art, opened its doors in Gümüşsuyu on November 11, 2020. The Artisan Istanbul M Gallery welcomes guests with artworks, paintings, and sculptures in almost every corner of the hotel with its enchanting Bosphorus view.`,
    },
    // Daha fazla haber ekleyebilirsiniz
];

export default newsData;
