import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderSection from '../HeaderSection/HeaderSection';
import arrowIcon from '../../assets/images/next.svg';
import './Directors.css';
import seniors from '../../data/seniors.json';

export default function Seniors() {
    const navigate = useNavigate();

    const handleCardClick = (senior) => {
        navigate(`${senior.id}`, {
            state: {
                name: senior.name,
                image: senior.image,
                bio: senior.bio,
                title: senior.title,
            },
        });
    };

    return (
        <section className="about-overview-section">
            <HeaderSection
                title="Senior Management"
                menuText="About"
                menuText2="Senior Management"
                icon={arrowIcon}
            />
            <div className="container">
                <div className="seniors-grid">
                    {seniors.map((senior) => (
                        <div className="director-card" onClick={() => handleCardClick(senior)}>
                        <img
                            src={senior.image}
                            alt={senior.name}
                            className="director-image"
                        />
                        <div className="director-layer"></div> {/* Yeni layer */}
                        <div className="director-info">
                            <p className="director-name">{senior.name}</p>
                            <p className="director-title">{senior.title}</p>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
