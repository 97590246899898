import React from 'react'
import './Overview.css'
import arrowIcon from '../../assets/images/next.svg'
import image from '../../assets/images/overview-image.jpeg'
import HeaderSection from '../HeaderSection/HeaderSection'

export default function Overview() {
    return (
        <section className='about-overview-section'>
            <HeaderSection
                title="About Us" 
                menuText="About Us" 
                icon={arrowIcon}
            />
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-6">
                        <h2 className='overview-title'>Overview</h2>
                        <p className="overview-text">
                            MV Holding was founded in 1993 by Murat Vargı, a prominent Turkish entrepreneur.
                        </p>
                        <p className="overview-text">In 2022, IFRS consolidated assets totalled 495 million USD and annual revenue was at 630 million USD. The Group has over 1888 employees.</p>
                        <p className="overview-text">MV Holding is co-founder of Turkcell, the leading GSM operator, and founder and major shareholder of KVK, the leading mobile device distributor in Turkey. The Group continues to create value in technology, hospitality and renewable energy sectors.</p>
                        <p className="overview-text"><span>Our Mission</span> is to create value for all our stakeholders through our pioneering enterprises and focus on sustainable growth.</p>
                        <p className="overview-text"><span>Our Vision</span> is to put our signature to successes that make a difference at a global level.</p>
                    </div>
                    <div className="col-md-6">
                        <img className='overview-image' src={image} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}
