import React from 'react'
import './Overview.css'
import arrowIcon from '../../assets/images/next.svg'
import image from '../../assets/images/founder-image.png'
import HeaderSection from '../HeaderSection/HeaderSection'

export default function Founder() {
    return (
        <section className='about-overview-section'>
            <HeaderSection
                title="Murat Vargı"
                menuText="About Us"
                icon={arrowIcon}
                menuText2="Founder & Honorary President"
            />
            <div className="container">
                <div className="row py-5 d-flex justify-content-between align-items-center">
                    <div className="col-md-7">
                        <h2 className='overview-title'>Founder and Honorary President of MV Holding</h2>
                        <p className="overview-text">Murat Vargi is the Founder and Honorary President of MV Holding.</p>
                        <p className="overview-text">Prior to founding MV Holding in 1993, Mr. Vargı acted as a founding shareholder of Turkcell Telecom Company and KVK Mobile Technology Company.</p>
                        <p className="overview-text">In early 90’s, Mr. Vargi was instrumental in setting up Turkcell, the first cellular telecommunication company in Turkey, and for many years served as Member of Board of Directors.</p>
                        <p className="overview-text">Murat Vargi started his career as an export representative in 1972 at Koç Holding.</p>
                        <p className="overview-text">Before establishing MV Holding, Mr. Vargi was one of the three founding partners of Penta Foreign Trade Company in 1976.</p>
                        <p className="overview-text">Murat Vargi holds a B.A. degree in International Trade and Economics from Ankara University  and License from Helsinki School of Economics on Trade Promotion as a fellow of UNTD. He also completed a scholarship program at the Indian Institute of Foreign Trade, New Delhi.</p>
                        <p className="overview-text">Today, Mr. Vargi is the Founder and Honorary President of MV Holding, Member of the Board of Trustees of Turkish Education Association TED İstanbul College, Member of the Board of Trustees of  the Educational Volunteers Foundation of Turkey (TEGV) and Founder and President of Mind Your Waste Foundation.</p>
                    </div>
                    <div className="col-md-4">
                        <img className='overview-image' src={image} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}
