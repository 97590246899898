// src/components/Press/Press.jsx
import React from 'react';
import HeaderSection from '../HeaderSection/HeaderSection';
import bg from '../../assets/images/press-header.jpeg';
import PressCard from './PressCard';
import newsData from '../../data/newsData'; // Import centralized news data

export default function Press() {
  return (
    <section className='press-section'>
        <HeaderSection title='Press' menuText='Press' backgroundImage={bg} />
        <div className="container">
          {newsData.map(news => (
            <PressCard 
              key={news.id}
              image={news.image}
              title={news.title}
              text={news.content}
              date={news.date}
              link={`/press/news/1`} // Link to individual news detail
            />
          ))}
        </div>
    </section>
  );
}
