import React from 'react';
import './Card.css';

export default function Card({ imageSrc, title, onClick }) {
    return (
        <div className="card-component-section" onClick={onClick} role="button" tabIndex={0}>
            <img
                src={imageSrc}
                alt={`Card ${title} image`}
                className="card-component-image"
            />
            <div className="card-component-layer">
                <p className="card-component-name">{title}</p>
            </div>
        </div>
    );
}
