// src/components/Technology.js
import React from 'react';
import turkcell from '../../assets/images/turkcell.png';
import kvk from '../../assets/images/kvk.png';
import paycore from '../../assets/images/paycore.png';
import livinsoft from '../../assets/images/livinsoft.png';
import InvestmentCard from '../Investments/InvestmentCard';
import HeaderSection from '../HeaderSection/HeaderSection';
import arrowIcon from '../../assets/images/next.svg';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import bg from '../../assets/images/investments-header.jpeg';

export default function Technology() {
    const cardsData = [
        {
            image: turkcell,
            title: 'Turkcell',
            text: `MV Holding, Türkiye’nin lider telekomünikasyon şirketi olan Turkcell’in kurucu ortaklarındandır ve 2023 yılsonu itibarıyla yaklaşık 1% oranında hissedarıdır. Türkiye’de mobil iletişim, Şubat 1994’te Turkcell’in hizmete girmesiyle başlamıştır. Turkcell, yerleşik, entegre iletişim ve teknoloji hizmetleri şirketidir. Müşterilerine mobil ve sabit şebekeleri üzerinden ses, data, TV hizmetleri ve katma değerli bireysel ve kurumsal servisler sunmaktadır. 43 milyon abonesi ile Turkcell aynı zamanda New York Borsası’nda kote ilk Türk şirketidir. Türkiye dışında Ukrayna, Belarus, Almanya, Hollanda ve Kuzey Kıbrıs’ta faaliyet gösteren iştirakleri ile toplam abone sayısı 56 milyonu bulmaktadır. Turkcell, dünyada HSPA+ teknolojisini kullanan ilk operatörlerden biri olmuştur.`,
            link: 'https://www.turkcell.com.tr/'
        },
        {
            image: kvk, // Residorm için görsel yolu
            title: "KVK",
            text: `KVK, 1993 yılında Çukurova Holding ve MV Holding ortaklığında kurulmuş olup, 2015 yılında MV Holding ana hissedarı olmuştur. 1994 yılından bu yana mobil iletişim ürünleri satış ve satış sonrası hizmet sektöründe faaliyet gösteren KVK Türkiye’nin en köklü markalarından birisidir. Turkcell, Samsung, Apple, LG, Vestel, Casper, Sony, HTC, Asus, Lenovo, ZTE, General Mobile, Huawei gibi güçlü markalarla işbirliği yapan KVK; kurulduğu tarihten itibaren 30 milyonun üzerinde cep telefonu, milyonlarca adet SIM kart ve kontör kartı satışı gerçekleştirmiştir. Ana işi cep telefonu distribütörlüğü olan KVK Türkiye’de 1500 noktanın üzerinde iş ortağı aracılığı ile ürünlerini tüketiciler ile buluşturmaktadır. Şirket, 1997 yılında satış sonrası hizmetler alanında da faaliyet göstermeye başlamıştır. Satış sonrası hizmetler, Haziran 2007’den bu yana KVK Teknik Servis Hizmetleri ve Tic. A.Ş. adı altında ayrı bir şirket olarak faaliyetine devam etmektedir.`,
            link: "http://www.kvk.com.tr/"
        },
        {
            image: paycore, // Casa Foscolo için görsel yolu
            title: "Paycore",
            text: `Ödeme sistemleri alanındaki yazılım ihtiyacını karşılamak üzere 2001 yılında kurulan PayCore, bankacılık, telekom, ödeme hizmet sağlayıcıları, kart kişiselleştirme büroları, kamu, ulaşım ve perakende sektörlerine ödeme teknolojileri alanında yenilikçi ve uçtan uca çözümler sunmaktadır. Kart yönetim yazılımları, dijital ödeme çözümleri, akıllı şehir uygulamaları, EMV çözümleri ve güvenli donanım hizmetleri alanlarında yerel ve uluslararası düzeyde 30’dan fazla ödülün sahibi olan Paycore, 150’yi aşkın kurumun çözüm ortağı olarak 35’ten fazla ülkeye teknoloji ihraç etmiş bir firma.
Ürettiği teknoloji ile Avrupa, Amerika, Orta Doğu ve Hindistan’da 20’den fazla global ilke imza atan PayCore’un ilkleri arasında; ilk temassız kredi kartı, kol saati üzerinden ödeme, ilk ekranlı kredi kartı, ulaşımdaki ilk temassız kredi kartı uygulaması, cepte cüzdan, yeni nesil yazar kasa POS entegrasyonu, televizyon üzerinden alışveriş uygulaması, taksilerde dijital ödeme, akaryakıt sektöründe SMS ile çalışan ödeme sistemleri, akıllı kimlik ve ödeme özelliklerinin NFC teknoloji ile birleştirdiği ilk ulusal mobil cüzdan yer almaktadır.`,
            link: "http://www.paycore.com"
        },
        {
            image: livinsoft, // Residorm için görsel yolu
            title: "Livinsoft",
            text: `Livinsoft, öncelikle öğrenci yurtları işletmesini hedef alan ve MV Holding’in konaklama sektöründeki tecrübelerinden faydalanarak geliştirilmiş bulut tabanlı bir yazılım ürünüdür. Öğrenci konaklama sektörünün hızla geliştiği dünyamızda, işletme yönetimini kolaylaştıracak kapsamlı bir yazılıma ihtiyaç duyulması sebebi ile Livinsoft ortaya çıkmıştır. 2018 yılından itibaren sektörde kullanılmaya başlanan Livinsoft’un öne çıkan özellikleri arasında kullanıcı dostu arayüzü, kolay kurulumu, yüksek güvenlikli ve düşük maliyetli bir yapıya sahip olması yer almaktadır.`,
            link: "http://www.livinsoft.com"
        }
    ];

    return (
        <div>
            <Navbar />
            <section className='hospitality-section'>
                <HeaderSection
                    title='Technology'
                    menuText='Investments'
                    menuText2='Technology'
                    backgroundImage={bg}
                    icon={arrowIcon}
                />
                <div className="hospitality-cards-container">
                    {cardsData.map((card, index) => (
                        <InvestmentCard
                            key={index}
                            image={card.image}
                            title={card.title}
                            text={card.text}
                            link={card.link}
                        />
                    ))}
                </div>
            </section>
            <Footer />
        </div>
    );
}
