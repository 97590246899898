import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import About from '../components/About/About';
import Footer from '../components/Footer/Footer';
import Hero from '../components/Hero/Hero';
import News from '../components/News/News';
import Social from '../components/Social/Social';
import CookieBanner from '../components/Cookie/CookieBanner';

export default function HomePage() {
  return (
    <div>
        <Navbar />
        <CookieBanner />
        <Hero />
        <About />
        <News />
        <Social />
        <Footer />
    </div>
  )
}
