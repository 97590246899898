// src/components/HospitalityCard.js
import React from 'react';
import './InvestmentCard.css'; // Separate CSS for the card component

export default function InvestmentCard({ image, title, text, link }) {
    return (
        <div className="investment-card-component">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 d-flex justify-content-start align-items-center">
                        <img className='hospitality-image' src={image} alt={title} />
                    </div>
                    <div className="col-md-7 d-flex flex-column justify-content-around">
                        <h2 className='hospitality-title'>{title}</h2>
                        <p className='hospitality-text'>{text}</p>
                        <a
                            className="hospitality-button"
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Visit Site
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
