import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderSection from '../HeaderSection/HeaderSection';
import arrowIcon from '../../assets/images/next.svg';
import './Directors.css';
import directors from '../../data/directors.json';

export default function Directors() {
    const navigate = useNavigate();

    const handleCardClick = (director) => {
        navigate(`${director.id}`, {
            state: {
                name: director.name,
                image: director.image,
                bio: director.bio,
                title: director.title,
            },
        });
    };

    return (
        <section className="about-overview-section">
            <HeaderSection
                title="Board Of Directors"
                menuText="About Us"
                menuText2="Board Of Directors"
                icon={arrowIcon}
            />
            <div className="container">
                <div className="directors-grid">
                    {directors.map((director) => (
                        <div className="director-card" onClick={() => handleCardClick(director)}>
                            <img
                                src={director.image}
                                alt={director.name}
                                className="director-image"
                            />
                            <div className="director-layer"></div> {/* Yeni layer */}
                            <div className="director-info">
                                <p className="director-name">{director.name}</p>
                                <p className="director-title">{director.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
