import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import Press from '../components/Press/Press'

export default function PressPage() {
  return (
    <div>
        <Navbar />
        <Press />
        <Footer />
    </div>
  )
}
