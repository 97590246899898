// src/components/Hero/Hero.jsx
import React, { useState } from "react";
import "./Hero.css";
import { ReactComponent as TechnologyIcon } from "../../assets/images/computer.svg";
import { ReactComponent as EnergyIcon } from "../../assets/images/energy.svg";
import { ReactComponent as HospitalityIcon } from "../../assets/images/hotel.svg";
import logoIcon from "../../assets/images/logo-sade.png";
import logo from "../../assets/images/logo-big.png";

// Import additional background images
import techBg from "../../assets/images/tech-bg.jpeg";
import energyBg from "../../assets/images/energy-bg.jpeg";
import hospitalityBg from "../../assets/images/hospitality-bg.jpeg";
import defaultBg from "../../assets/images/hero-bg.jpeg";

function Hero() {
  const [activeMenu, setActiveMenu] = useState(1);

  const menuItems = [
    { 
      id: 1, 
      name: "", 
      icon: logo,
      // Default content
      background: defaultBg,
      title: "The Innovative Force Shaping the Future",
      subtitle: "Since 1993, MV Holding continues to be the power behind Turkey's leading brands with its innovative investments and sustainable growth approach in the technology, hospitality and energy sectors.",
      buttonLink: "/#about",
      buttonText: "Learn more"
    },
    { 
      id: 2, 
      name: "Technology", 
      icon: TechnologyIcon,
      background: techBg,
      title: "Technology",
      subtitle: "MV Holding invests in transformative technologies, driving progress in communication, fintech, and digital solutions to shape the future.",
      buttonLink: "/investments/technology",
      buttonText: "Explore Technology"
    },
    { 
      id: 3, 
      name: "Energy", 
      icon: EnergyIcon,
      background: energyBg,
      title: "Energy",
      subtitle: "MV Holding is committed to advancing renewable and sustainable energy initiatives, powering a cleaner and greener future.",
      buttonLink: "/investments/energy",
      buttonText: "Discover Energy"
    },
    { 
      id: 4, 
      name: "Hospitality", 
      icon: HospitalityIcon,
      background: hospitalityBg,
      title: "Excellence in Hospitality",
      subtitle: "MV Holding invests in the hospitality sector, creating exceptional experiences and setting new standards in comfort and service.",
      buttonLink: "/investments/hospitality",
      buttonText: "Visit Hospitality"
    },
  ];

  const activeItem = menuItems.find(item => item.id === activeMenu) || menuItems[0];

  return (
    <section 
      className="hero-section" 
      style={{ backgroundImage: `url(${activeItem.background})` }}
    >
      {/* Overlay Layer */}
      <div className="overlay"></div>

      {/* Main Hero Content */}
      <div className="container text-white d-flex flex-column justify-content-start align-items-start hero-content">
        <img className="hero-logo" width={100} src={logoIcon} alt="MV Holding Logo" />
        <h1 className="hero-title">{activeItem.title}</h1>
        <p className="hero-subtitle">
          {activeItem.subtitle}
        </p>
        <button className="hero-button">
          <a href={activeItem.buttonLink}>{activeItem.buttonText}</a>
        </button>
      </div>

      {/* Hero Menu */}
      <div className="hero-menu">
        {menuItems.map((item) => (
          <button
            key={item.id}
            className={`menu-item ${activeMenu === item.id ? "active" : ""}`}
            onClick={() => setActiveMenu(item.id)}
          >
            {item.id !== 1 ? (
              <item.icon
                className="menu-icon"
                aria-hidden="true"
              />
            ) : (
              <img src={item.icon} alt="Logo Icon" className="menu-icon" />
            )}
            {item.name && <span className="menu-text">{item.name}</span>}
          </button>
        ))}
      </div>
    </section>
  );
}

export default Hero;
