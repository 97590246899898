import React from 'react'
import Navbar from '../Navbar/Navbar'
import HeaderSection from '../HeaderSection/HeaderSection'
import InvestmentCard from '../Investments/InvestmentCard'
import Footer from '../Footer/Footer'
import arrowIcon from '../../assets/images/next.svg'
import tog from '../../assets/images/tog.png'
import podem from '../../assets/images/podem.png'
import microcredit from '../../assets/images/microcredit.png'
import sendegel from '../../assets/images/sendegel.png'
import women from '../../assets/images/women.png'
import endeavor from '../../assets/images/endeavor.png'
import common from '../../assets/images/common.png'
import bg from '../../assets/images/social-header.jpeg'


export default function Society() {

    const cardsData = [
        {
            image: tog,
            title: 'TOG – The Community Volunteers Foundation',
            text: `In the leadership of young people, it was founded in 2002 for social peace, solidarity and change and it aims at realizing social service projects for this purpose.
It aims at contributing to the creation of a self-reliable, initiative and sensitive young society.`,
            link: 'http://www.tog.org.tr/'
        },
        {
            image: podem, // Residorm için görsel yolu
            title: "PODEM – The Center for Public Policy and Democracy Studies",
            text: `The Center for Public Policy and Democracy Studies (PODEM) is an Istanbul-based independent think tank founded in February 2015.
PODEM seeks to contribute to the efforts towards the creation of a Turkey where democracy is fully institutionalized, and where a democratic mindset, social peace and justice prevail. It further envisions a Turkey that is increasingly influential in the establishment of peace and justice on regional and global levels.`,
            link: "http://www.podem.org.tr/"
        },
        {
            image: microcredit, // Casa Foscolo için görsel yolu
            title: "Turkish Grameen Microcredit Program",
            text: `Led by Muhammad Yunus, a Bangladeshi economist to be awarded with the Nobel Peace Prize in 2006, granted small loans to Bangladeshi women for enabling them to start their own businesses. Yunus conducts his activities in the Americas as well as Kosovo, Turkey, Zambia, Costa Rica, Guatemala and Indonesia. In Turkey, the Grameen Microcredit Program conducts its activities in Diyarbakir by the cooperation between the Turkish Foundation for Preventing Waste and the GrameenTrust.
MV Holding Balıkesir Microcredit Branch was opened in 14 July 2009 and first credit has been allocated.`,
            link: "http://www.tgmp.net/"
        },
        {
            image: sendegel, // Residorm için görsel yolu
            title: "Sen de Gel Association",
            text: `Women on Board Association
The main objective of the Women on Board Turkey Association, established in January 2017, is to promote social development by increasing female representation on boards of directors.
WOB Turkey aims at fulfilling its social development vision by preparing women at the executive level of companies for their parts on Boards of Directors and by working at persuading its stakeholders to share this vision.`,
            link: "http://www.sendegel.org.tr/"
        },
        {
            image: women, // The LifeCo için görsel yolu
            title: "Women on Board Association",
            text: `The main objective of the Women on Board Turkey Association, established in January 2017, is to promote social development by increasing female representation on boards of directors.
WOB Turkey aims at fulfilling its social development vision by preparing women at the executive level of companies for their parts on Boards of Directors and by working at persuading its stakeholders to share this vision.`,
            link: "https://www.yonetimkurulundakadin.org/"
        },
        {
            image: endeavor, // Residorm için görsel yolu
            title: "Endeavor Association",
            text: `Endeavor is an organization headquartered in New York City that supports entrepreneurs with potential for economic and social impact in their regions. The organization provides the entrepreneurs in its network with services that help them grow ventures, create jobs, transform economies, and support future generations of entrepreneurs.`,
            link: "https://endeavor.org.tr/"
        },
        {
            image: common, // The LifeCo için görsel yolu
            title: "Common Purpose Association",
            text: `Leaders who can cross boundaries. Both at work and in society.
Common Purpose delivers leadership programmes in over 100 cities worldwide.`,
            link: "https://commonpurpose.org/turkiye/"
        }
    ];

    return (
        <div>
            <Navbar />
            <section className='hospitality-section'>
                <HeaderSection title='Society & Economy' menuText='Social Responsibility' menuText2='Society & Economy' icon={arrowIcon} backgroundImage={bg} />
                <div className="hospitality-cards-container">
                    {cardsData.map((card, index) => (
                        <InvestmentCard
                            key={index}
                            image={card.image}
                            title={card.title}
                            text={card.text}
                            link={card.link}
                        />
                    ))}
                </div>
            </section>
            <Footer />
        </div>
    )
}
