import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import Seniors from '../components/About/Seniors'

export default function SeniorsPage() {
  return (
    <div>
        <Navbar />
        <Seniors />
        <Footer />
    </div>
  )
}
