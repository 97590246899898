import React, { useState, useRef, useEffect } from 'react';
import './Social.css';
import image1 from '../../assets/images/sanat1.jpeg';
import image2 from '../../assets/images/economy-news.jpeg';
import image3 from '../../assets/images/environment-news.jpeg'
import image4 from '../../assets/images/education-news.png'
import arrowUp from '../../assets/images/arrowUp.svg';
import arrowDown from '../../assets/images/arrowDown.svg';
import logo from '../../assets/images/logo-sade.png';

function AccordionItem({ title, backgroundImage, children, isActive, onClick }) {
    const contentRef = useRef(null);
    const [height, setHeight] = useState('0px');

    useEffect(() => {
        if (isActive) {
            setHeight('500px');
        } else {
            setHeight('0px');
        }
    }, [isActive]);

    return (
        <div className={`social-accordion-item ${isActive ? 'expanded' : ''}`}>
            {!isActive && (
                <button
                    className={`social-accordion-header ${isActive ? 'active' : ''}`}
                    onClick={onClick}
                    aria-expanded={isActive}
                    aria-controls={`accordion-content-${title}`}
                >
                    {title}
                    <span>
                        <img src={arrowDown} alt="Arrow Down" />
                    </span>
                </button>
            )}
            <div
                id={`accordion-content-${title}`}
                className={`social-accordion-content ${isActive ? 'open' : 'closed'}`}
                style={{ backgroundImage: `url(${backgroundImage})`, height: height }}
                ref={contentRef}
            >
                <div className="social-accordion-header-content">
                    <h2 className="social-accordion-title">{title}</h2>
                    <button
                        type="button"
                        className="close-icon"
                        onClick={onClick}
                        aria-label="Close Accordion"
                    >
                        <img src={arrowUp} alt="Arrow Down" />
                    </button>
                </div>
                <div className="social-accordion-footer">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default function Social() {

    const [activeAccordion, setActiveAccordion] = useState("Education");
    const handleAccordionClick = (title) => {
        setActiveAccordion((prev) => (prev === title ? null : title));
    };

    return (
        <div className="social-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-12">
                        {/* Header */}
                        <div className="row social-title-section">
                            <div className="col-8">
                                <span className="social-highlight">Social Responsibility</span>
                            </div>
                            <div className="col-4 text-end">
                                <a href="/social/education" className="view-all">
                                    View All
                                </a>
                            </div>
                        </div>

                        <div className="row social-subtitle-section">
                            <div className="col-12">
                                <p className="social-title">
                                    Our Commitment to Social Impact <span className="social-title-highlighted">Social Impact</span>
                                </p>
                            </div>
                        </div>

                        {/* Description */}
                        <p className="social-description">
                            At MV Holding, we believe in creating a lasting impact beyond business. Through innovative initiatives in education, sustainability, and technology, we strive to inspire progress and empower communities for a brighter future.
                        </p>
                        {/* Accordion */}
                        <div className="accordion-section">
                            <AccordionItem
                                title="Art"
                                backgroundImage={image1}
                                isActive={activeAccordion === "Art"}
                                onClick={() => handleAccordionClick("Art")}
                            >
                                <p>
                                    MV Holding continues to support art with the permanent art exhibition in Swissotel Buyuk Efes Izmir.
                                </p>
                            </AccordionItem>
                            <AccordionItem
                                title="Society & Economy"
                                backgroundImage={image2}
                                isActive={activeAccordion === "Society & Economy"}
                                onClick={() => handleAccordionClick("Society & Economy")}
                            >
                                <p>
                                    We aim to contribute to society and the economy by supporting Turkey's leading foundations and non-governmental organizations.
                                </p>
                            </AccordionItem>
                            <AccordionItem
                                title="Environment"
                                backgroundImage={image3}
                                isActive={activeAccordion === "Environment"}
                                onClick={() => handleAccordionClick("Environment")}
                            >
                                <p>
                                    MV Holding has recently been instrumental in the establishment of Mind Your Waste Foundation for environmental cleanliness.
                                </p>
                            </AccordionItem>
                            <AccordionItem
                                title="Education"
                                backgroundImage={image4}
                                isActive={activeAccordion === "Education"}
                                onClick={() => handleAccordionClick("Education")}
                            >
                                <p>
                                    MV Holding aims to contribute to education by supporting Turkey's leading foundations, non-governmental organizations and initiatives.
                                </p>
                            </AccordionItem>
                        </div>
                    </div>
                    <div className="col-md-1 col-0 line-sec">
                        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="610" viewBox="0 0 2 610" fill="none">
                            <path d="M1 0L1.00003 610" stroke="#D0D5DD" />
                        </svg>
                    </div>
                    <div className="col-md-5 col-12 subscription-section">
                        <img className='subscription-image' src={logo} alt="" />
                        <h2 className='subscription-title'>Be the first to know</h2>
                        <p className='subscription-text'>We’re still building. Subscribe for updates.</p>
                        <div className="subscription-form">
                            <input type="email" placeholder="Enter your email" />
                            <button>Subscribe</button>
                        </div>
                        <span className="privacy-note">We care about your data in our privacy policy.</span>
                    </div>
                </div>
            </div>
        </div>
    );
}