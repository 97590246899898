import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Investments from '../components/Investments/Investments'
import Footer from '../components/Footer/Footer'

export default function InvestmentsPage() {
  return (
    <div>
        <Navbar />
        <Investments />
        <Footer />
    </div>
  )
}
