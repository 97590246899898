import React from 'react'
import HeaderSection from '../HeaderSection/HeaderSection'
import arrowIcon from '../../assets/images/next.svg'
import waste from '../../assets/images/waste.png'
import turmepa from '../../assets/images/turmepa.png'
import Navbar from '../Navbar/Navbar'
import InvestmentCard from '../Investments/InvestmentCard'
import Footer from '../Footer/Footer'
import bg from '../../assets/images/social-header.jpeg'


export default function Environment() {

    const cardsData = [
        {
            image: waste,
            title: 'Mind Your Waste Foundation (Çöpüne Sahip Çık Vakfı)',
            text: `Mind Your Waste Foundation was founded by Murat Vargi in 2015. The mission of the foundation is to create a cleaner, sustainable environment by enhancing waste management, promoting a behavioral change to eliminate littering, minimize waste production and to ensure proper disposal of waste.`,
            link: 'www.tedistanbul.k12.tr'
        },
        {
            image: turmepa, // Residorm için görsel yolu
            title: "TURMEPA – DenizTemiz Association",
            text: `The DenizTemiz Association was founded by pioneers in maritime and business world in 1994.
The mission of the Association is to protect the environment for the next generations and ensuring the next generations to benefit from the seas’ contributions to our economy, health and prosperity.`,
            link: "http://www.egitimreformugirisimi.org"
        }
    ];

    return (
        <div>
            <Navbar />
            <section className='hospitality-section'>
                <HeaderSection title='Environment' menuText='Social Responsibility' menuText2='Environment' icon={arrowIcon} backgroundImage={bg} />

                <div className="container">
                    <div className="hospitality-central-section">

                        <div className="row">
                            <div className="col-12">
                                <p className='hospitality-subtext'>MV Holding has recently been instrumental in the establishment of Mind Your Waste Foundation for environmental cleanliness. MV Holding also contributes to environment by supporting the Deniztemiz-Turmepa Foundation.
                                Swissotel Buyuk Efes contributes to environmental awareness by planting trees and saving energy.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hospitality-cards-container">
                    {cardsData.map((card, index) => (
                        <InvestmentCard
                            key={index}
                            image={card.image}
                            title={card.title}
                            text={card.text}
                            link={card.link}
                        />
                    ))}
                </div>
            </section>
            <Footer />
        </div>

    )
}
